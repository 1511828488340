/* --- Noticias --*/
.noticias {
    width: 355px;
    box-shadow: 0px 10px 10px #00000029;
}

.noticia-card{
    border-radius: 10px 10px 0 0;
}



@media only screen and (max-width: 1200px) {
    .noticia-card{
        width: none;
    }
}

.breadcrumb .breadcrumb-item.active{
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 32px;
    max-width: 94%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.noticia-link:visited, .noticia-link:link {
    cursor: pointer;
    color: #000;
    text-decoration: none;
}

.noticia-img{
    width: 100%;
    overflow: hidden;
}

.noticia-body {
    width: 100%;
    border-bottom: 2px solid #7070707a;
}

.noticia-title {
    overflow: hidden;
    margin-top: 0.4em;
}

.noticia-title p {
    font-family: 'Barlow', sans-serif;
    font-size: 1.75em;
}

.noticia-title>p, .noticia-title>h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}


.noticia-copete {
    max-height: 190px;
    overflow: hidden;
    margin-bottom: 2em;
    overflow: auto;
}

.noticia-copete>p {
    font-family: 'Barlow', sans-serif;
    font-size: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    margin: 0;
}

.noticia-footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.noticia-date {
    font-family: 'Barlow', sans-serif;
    color: #2C2E35;
    font-size: 1em;
}

.seccion-last-news {
    display: flex;
    justify-content: flex-end;
}

.last-news-container {
    background-color: white;
    border: 1px solid grey;
    border-radius: 1rem;
    width: 100%;
}

.last-news-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .card.noticias {
    border-radius: 0px!important;
}

.card.noticias .card-body {
    padding: 0px;
}

.card.noticias .card-body *:not(div) {
    font-size: 20px;
    padding: 30px 20px 0px 20px;
    font-weight: normal;
    text-transform: none!important;
    margin-bottom: 0px;
}

.card.noticias .card-body p {
    padding: 10px 20px 0px 20px;
    font-size: 17px;
    font-weight: 300;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    margin-bottom: 30px;
}

.card.noticias .cardImg {
    height: 200px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
}


.card.noticias .card-footer-transparent {
    margin-top: 0px;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    font-weight: 400;
} */